import React from 'react'
import Layout from '../components/indexLayout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Obfuscate from 'react-obfuscate'

//style={{ backgroundColor: 'red', color: 'white' }}
const ImpressumPage = props => (
  <Layout>
    <div className="page">
      <div className="container">
        <section className="hero-section3">
          <div className="hero-texts">
            <p className="hero-header">
              Impressum
            </p>
            <div className="dash" />
            <p>
              <strong>Vogl-Immobilien</strong><br />
              Rathmecker Weg 7<br />
              58513 Lüdenscheid<br />
              
              E-Mail: <Obfuscate className="dark-link" linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." email="frank-vogl@t-online.de" /><br/>
              Telefon: <Obfuscate className="dark-link" linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." tel="0151-64717999" />
              <br /><br />
              Rechtliche Angaben<br />
              Gesetzlicher Vertreter: Frank Vogl<br />
              USt-IdNr.: 92604789513<br />
              Registergericht: Lüdenscheid<br />
              Gewerbeerlaubnis gem. §34c GewO wurde erteilt durch:<br />
              Landkreis Schaumburg<br />
              Der Landrat<br />
              31653 Stadhagen
              <br /><br />
              Zuständige Aufsichtsbehörde:<br />
              Märkischer Kreis<br />
              Der Landrat<br />
              Heedfelder Str. 45<br />
              58509 Lüdenscheid
            </p>
          </div>
          <div>
            <Img
              fluid={props.data.imageOne.childImageSharp.fluid}
              className="hero-image"
              alt="hero image"
            />
          </div>
        </section>
      </div>

      <div className="container">
        <section className="hero-section2">
          <Img
            fluid={props.data.imageTwo.childImageSharp.fluid}
            className="hero-image"
            alt="hero image"
          />
          <div className="hero-texts">
            <p className="hero-header">Verbraucher­informationen</p>
            <div className="dash" />
            <p>
              Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:<br />
              Die Europäische Kommission stellt unter <a href="https://ec.europa.eu/consumers/odr/main/index.cfm" target="_blank" rel="noopener noreferrer">
                https://ec.europa.eu/consumers/odr/main/index.cfm</a> eine Plattform zur Online-Streitbeilegung (OS) bereit.
            </p>
          </div>
        </section>
      </div>

    </div>
  </Layout>
)

export const firstSiteImages = graphql`
  fragment firstSiteImages on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "images/undraw_Working_oh83_transparent.png" }) {
      ...firstSiteImages
    }
    imageTwo: file(relativePath: { eq: "images/undraw_online_information_4ui6_transparent.png" }) {
      ...firstSiteImages
    }
  }
`

export default ImpressumPage
